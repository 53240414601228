<template>
  <svg
    ref="spinnerRef"
    width="70"
    height="70"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :class="[
        'origin-center fill-blue-500 animate-spin',
        { 'animate-pulse': isVisible }
      ]"
      d="M12,23a9.63,9.63,0,0,1-8-9.5,9.51,9.51,0,0,1,6.79-9.1A1.66,1.66,0,0,0,12,2.81h0a1.67,1.67,0,0,0-1.94-1.64A11,11,0,0,0,12,23Z"
    />
  </svg>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const spinnerRef = ref(null)
const isVisible = ref(true)
let timeoutId = null

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach(entry => {
      isVisible.value = entry.isIntersecting
      if (isVisible.value) {
        timeoutId = setTimeout(() => {
          window.location.href = window.location.href
        }, 7000)
      }
    })
  },
  { threshold: 0 }
)

watch(isVisible, (newValue) => {
  if (!newValue) {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
  }
})

onMounted(() => {
  if (spinnerRef.value) {
    observer.observe(spinnerRef.value)
  }
})

onBeforeUnmount(() => {
  observer.disconnect()
  if (timeoutId) {
    clearTimeout(timeoutId)
  }
})
</script>
