<script setup>
import { ref, computed } from 'vue';
import { RouterLink, RouterView, useRoute } from 'vue-router';
import NavSidebar from '../src/components/tailwindCSS/NavSidebar.vue';
import { stytch } from './stytch';
import { onBeforeUnmount } from 'vue';
import { loadingStore } from './stores/appStateStore';
import Spinner from '@/components/common/Spinner.vue';
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary.vue';
import Tooltip from '@/components/common/Tooltip.vue';
import { provideTooltip } from '@/composables/useTooltip';
import { useToast } from './plugins/toast';
import Toast from './components/common/toast/Toast.vue';
import { identifyUser, setUserProperties } from '@/services/analytics.service';

import { ConfigProvider } from 'radix-vue';

const { visible, toast, hideToast, handleAction } = useToast();

// Define the routes where the sidebar should be visible
const routesWithSidebar = ref([
  'Library Viewer',
  'Project Viewer',
  'Tags Viewer',
  'Product Lines',
  'Product Types',
  'Profile Viewer',
  'Specifier Viewer',
  'Product Type Details',
  'Product Line Details',
  'Project Detail Viewer',
  'Shared Projects Viewer',
  'Inbox Viewer',
]);

// Access the current route
const route = useRoute();
const loadingStateStore = loadingStore();

// Define the computed variable
const showSidebar = computed(() =>
  routesWithSidebar.value.includes(route.name)
);

/*
Session logic

The logic below listens to changes on the Stytch Session to:
- redirect newly authenticated traffic to /profile.
- redirect the user to login view if they log out.
*/
import router from './router';
import axios from 'axios';

const unsubscribe = stytch.session.onChange(async (session) => {
  if (session) {
    let userId = session.custom_claims?.userId;
    await axios
      .get('/v1/getToken/' + userId)
      .then((res) => res.data)
      .then((data) => {
        identifyUser(`${session.custom_claims.name}::${data.userId}`, {
          email: session.custom_claims.email,
        });
        setUserProperties({
          $name: session.custom_claims.name,
          $email: session.custom_claims.email,
          $last_seen: Date(),
          'User ID': data.userId,
          'Org ID': data.orgId,
        });
        document.cookie = `auth_token=${data.access_token}; Secure; SameSite=Strict; Max-Age=${data.expires_in}; Path=/;`;
        const sessionData = {
          userId: data.userId,
          orgId: data.orgId,
          email: data.email,
          orgName: data.orgName,
          username: session.custom_claims.name,
          roleType: data.roleType,
          isAIPoweredSuggestionsEnabled: data.isAIPoweredSuggestionsEnabled,
          isProductsEnabled: data.isProductsEnabled,
          isRequestProEnabled: data.isRequestProEnabled,
        };

        Object.entries(sessionData).forEach(([key, value]) => {
          sessionStorage.setItem(key, value);
        });
      });
    const redirectPath = route.query.redirect;
    if (redirectPath) {
      router.push(redirectPath);
    } else if (route.name === 'login') {
      router.push({ name: 'Project Viewer' });
    }
  } else {
    router.push({ name: 'login' });
  }
});

onBeforeUnmount(() => {
  unsubscribe && unsubscribe();
});

const tooltipRef = ref(null);

provideTooltip({
  open: (id, description, title, position) => {
    tooltipRef.value?.open(id, description, title, position);
  },
  close: () => {
    tooltipRef.value?.close();
  },
});

const refresh = () => {
  if (stytch.session.getSync()) {
    stytch.session.authenticate({
      session_duration_minutes: 60 * 24 * 14,
    });
  }
};

// Refresh session every 50 minutes
let interval = setInterval(refresh, 3000000);
window.addEventListener('beforeunload', () => {
  clearInterval(interval);
});
</script>

<template>
<ConfigProvider :scrollBody='{padding: "0"}'>
  <div
    class="fixed left-0 top-0 z-[999] h-5 w-screen animate-pulse bg-sky-600"
    v-if="loadingStateStore.isLoading && $route.path.includes('login')"
  ></div>
  <FeedbackButton />
  <NavSidebar v-if="showSidebar" :curr-route="route.name">
    <div v-show="loadingStateStore.isLoading" class="h-dvh">
      <Spinner class="absolute inset-0 m-auto" />
    </div>
    <div v-show="!loadingStateStore.isLoading" class="size-full">
      <ErrorBoundary>
        <RouterView />
      </ErrorBoundary>
    </div>
  </NavSidebar>

  <!-- a hack to style editor viewer separately -->
  <div v-else class="h-full" :class="{'flex w-full flex-col p-8': route.name!== 'Editor Viewer'}">
    <Suspense>
      <ErrorBoundary>
        <RouterView />
      </ErrorBoundary>
      <template #fallback>
        <Spinner class="absolute inset-0 m-auto" />
      </template>
    </Suspense>
  </div>

  <Tooltip ref="tooltipRef" />
  <Toast
    :visible="visible"
    :text="toast.text"
    :actionLabel="toast.actionLabel"
    :actionHandler="toast.actionHandler"
    @close="hideToast"
  />
</ConfigProvider>
</template>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}
</style>
